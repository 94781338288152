.breadcrumb {
  @apply my-4;
}
.breadcrumb ul {
  @apply m-0;
}
.breadcrumb li {
  @apply inline-block mr-4 after:content-['|'] capitalize;
}
.breadcrumb li:last-child {
  @apply after:content-[''];
}
.breadcrumb li a {
  @apply pr-4 inline-block;
}
