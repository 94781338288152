.dark,
.light {
  @apply block;
}

.dark .name {
  @apply fill-green;
}

.dark .logo {
  @apply fill-blue;
}

.dark .dot {
  @apply fill-green;
}

.light .name {
  @apply fill-green;
}

.light .logo {
  @apply fill-gray-200;
}

.light .dot {
  @apply fill-green;
}
