.btn {
  @apply rounded-lg text-white hover:text-white focus:text-white text-center font-medium uppercase inline-flex justify-center items-center hover:shadow-md transition-colors ease-in-out duration-300;
}

.primary {
  @apply bg-blue hover:bg-green focus:ring-blue;
}

.secondary {
  @apply bg-green hover:bg-blue focus:ring-green;
}

.disabled {
  @apply bg-gray-400 cursor-not-allowed;
}

.sm {
  @apply px-2 lg:px-3 py-2 text-xs;
}

.base {
  @apply px-2 lg:px-4 xl:px-5 py-2 text-xs xl:text-sm;
}

.lg {
  @apply px-2 lg:px-6 py-2 text-base;
}

.loading {
  @apply cursor-wait relative;
}

.icon svg {
  @apply inline mr-2 -ml-1 fill-white;
}

.sm.icon svg {
  @apply w-5 h-5;
}

.base.icon svg {
  @apply w-8 h-8;
}

.lg.icon svg {
  @apply w-12 h-12;
}
