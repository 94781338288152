.dropdown {
  @apply hidden;
}

.rooms {
  @apply w-full md:w-[600px];
}

.regional {
  @apply w-full md:w-[400px];
}

.regional .main,
.rooms .main {
  @apply md:w-full;
}

.rooms li {
  @apply sm:w-1/3;
}

.regional li {
  @apply sm:w-1/2;
}

.dropdown.active {
  @apply block md:absolute md:top-12 xl:top-8;
}

.window {
  @apply p-5 md:rounded-xl bg-white/95 md:drop-shadow-lg;
  /* box-shadow: 5px 6px 8px rgba(var(--color-black) / 60%); */
}

.window ul {
  @apply list-none m-0;
}

.wrap {
  @apply flex;
}

.arrow {
  @apply hidden md:block w-0 h-0 ml-5 relative drop-shadow;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-bottom: 15px solid rgb(var(--color-white));
}
