.item {
  @apply flex items-center justify-between mb-1;
}

.item > div:first-child {
  @apply hidden sm:block mr-2 w-12 sm:w-18 relative;
}

.item > div:last-child {
  @apply w-full;
}

.item:hover .title {
  @apply text-green
}

.title {
  @apply text-xs uppercase text-blue sm:whitespace-nowrap;
}

.subtitle {
  @apply uppercase text-xxs text-gray leading-tight;
}
