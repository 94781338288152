.header {
  @apply pt-4 md:pt-10 pb-4;
}

.logo_wrap {
  @apply mb-6 md:mr-8 md:mb-0 block;
}

.logo_wrap svg {
  @apply m-auto;
}

.nav {
  @apply -mx-5 md:mx-0 p-4 md:p-0 md:w-full relative flex flex-row-reverse md:flex-col justify-around md:justify-between bg-blue md:bg-transparent;
}

.nav_top {
  @apply hidden md:flex md:items-center md:justify-between md:mb-6;
}

.nav_top div {
  @apply hidden md:flex;
}

.nav_top_active {
  @apply absolute block md:relative top-14 md:top-0 md:flex w-full py-4 px-8 md:p-0 text-center bg-gray-100 md:bg-transparent;
}

.nav_top a {
  @apply fill-blue hover:fill-green transition-colors ease-in-out duration-300;
}

.nav_top svg {
  @apply inline-block;
}

.nav_top_home {
  @apply hidden md:block;
}

.nav_top_link {
  @apply flex justify-center items-center text-sm text-gray;
}

.nav_top_link svg {
  @apply mr-2;
}

.contact_btn {
  @apply flex items-center md:hidden text-white text-sm hover:text-green transition-colors ease-in-out duration-300;
}

.contact_btn svg {
  @apply fill-white transition-colors ease-in-out duration-300;
}

.contact_btn span {
  @apply inline-block ml-2
}

.contact_btn:hover svg {
  @apply fill-green;
}

