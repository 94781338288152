.menu_btn {
  @apply flex items-center md:hidden text-white text-sm hover:text-green transition-colors ease-in-out duration-300;
}

.menu_btn svg {
  @apply fill-white transition-colors ease-in-out duration-300;
}

.menu_btn span {
  @apply inline-block ml-2;
}

.menu_btn:hover svg {
  @apply fill-green;
}

.menu {
  @apply m-0 md:w-auto absolute md:relative z-50 top-14 md:top-auto left-0 md:left-auto right-0 md:right-auto md:flex md:gap-1 py-4 md:py-0 bg-gray-100 md:bg-transparent;
}

.menu > li {
  @apply relative md:w-full md:flex md:content-between;
}

.menu > li > a {
  @apply md:w-full px-5 md:px-4 py-2 md:py-3 block md:flex md:justify-center md:items-center text-left md:text-center text-sm md:text-xs md:rounded-lg text-gray-300 hover:text-white md:text-white bg-gray-100 hover:bg-gray-300 md:bg-blue md:hover:bg-green uppercase md:hover:shadow-md transition-colors ease-in-out duration-300 leading-tight;
}

.menu > li > button {
  @apply w-full px-5 md:px-4 py-2 md:py-3 block md:flex md:justify-center md:items-center text-left md:text-center text-sm md:text-xs md:rounded-lg text-gray-300 hover:text-white md:text-white bg-gray-100 hover:bg-gray-300 md:bg-blue md:hover:bg-green uppercase md:hover:shadow-md transition-colors ease-in-out duration-300 leading-tight;
}

.menu > li > a.highlight {
  @apply md:bg-green md:hover:bg-blue;
}

.menu > li > button.highlight {
  @apply md:bg-green md:hover:bg-blue;
}

.title {
  @apply text-xs font-bold uppercase text-blue mb-1 sm:mb-2 leading-tight sm:whitespace-nowrap;
}

.main_ul {
  @apply sm:flex sm:flex-wrap sm:justify-start;
}

.main_list {
  @apply sm:w-[calc((100%/3)-.5rem)] sm:border-r border-gray-300 sm:mr-2 sm:pr-2;
}
.main_list:last-child {
  @apply border-0 mr-0 pr-0;
}

.main_list a {
  @apply text-xs block mb-2 md:mb-0;
}
